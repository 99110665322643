import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Form = _resolveComponent("Form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { title: "PLIP Report" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, { spinning: _ctx.isLoading }, {
        default: _withCtx(() => [
          (_ctx.auth_user == true)
            ? (_openBlock(), _createBlock(_component_DataTable, {
                key: 0,
                loading: _ctx.loading == 'loading',
                data: _ctx.items.data,
                columns: _ctx.fields,
                pagination: _ctx.items,
                onDownload: _ctx.downloadRecord,
                onEdit: _ctx.editRecord,
                onDestroy: _ctx.deleteRecord,
                onCreate: _ctx.createRecord,
                onFilter: _ctx.filterRecord
              }, null, 8, ["loading", "data", "columns", "pagination", "onDownload", "onEdit", "onDestroy", "onCreate", "onFilter"]))
            : _createCommentVNode("", true),
          (_ctx.auth_user == true)
            ? (_openBlock(), _createBlock(_component_Form, {
                key: 1,
                title: "Plip Report",
                visible: _ctx.visible,
                onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = false)),
                onSave: _ctx.handleSaving,
                data: _ctx.form
              }, null, 8, ["visible", "onSave", "data"]))
            : (_openBlock(), _createBlock(_component_a_row, {
                key: 2,
                style: {"margin-bottom":"15px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.downloadLatest()))
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Download Plip Report ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }))
}